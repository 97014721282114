body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.projTimeline {
  /* width: 1vw; */
  /* max-width: 20vw; */
  /* background-color: orange; */
}

.projectHolder {
  /* padding: 5rem; */
  padding-bottom: 5rem;
}

.mainPage {
  padding: 5rem;
}

@media only screen and (max-width: 600px) {
  .mainPage {
    padding: 1rem;
  }
}

@media only screen and (max-width: 800px) {
  .mainPage {
    padding: 2rem;
  }
}

.sunriseImgOverlay {
/* layout the second child on top of first child */
  position: relative;
  /* width: 100%; */
  /* height: 100%; */
  /* background-color: red; */

  
}
/* select second child of sunrise*/
.sunriseImgOverlay > :nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: blue; */
  z-index: 2;
  padding: 1rem;
}